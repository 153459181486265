import { ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  email?: string;
  name?: string;
  role?: string;
  permission?: string;
  id?: string;
}

export interface UserState {
  csrf: string | null;
  loggedIn: boolean;
  user: User | null;
  isCsrfMWRunning: boolean;
}

export const userState: UserState = {
  csrf: null,
  loggedIn: false,
  user: null,
  isCsrfMWRunning: false
};

const slice = createSlice({
  name: 'UserState',
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.csrf = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setIsCsrfMWRunning: (state, action: PayloadAction<boolean>) => {
      state.isCsrfMWRunning = action.payload;
    }
  },
  initialState: userState
});

export const setToken: ActionCreatorWithPayload<string> = slice.actions.setToken;
export const setLoggedIn: ActionCreatorWithPayload<boolean> = slice.actions.setLoggedIn;
export const setUser: ActionCreatorWithPayload<User | null> = slice.actions.setUser;
export const setIsCsrfMWRunning: ActionCreatorWithPayload<boolean> = slice.actions.setIsCsrfMWRunning;

export const userStateReducer = slice.reducer;
