import { useDispatch, useSelector } from 'react-redux';
import { setToken, setUser, UserState } from './redux/user.state';
import { useCallback, useEffect } from 'react';
import { getApiBaseUrl } from './utils/api';
import { GlobalState } from './redux/store';

export const useFetchUser = (): (() => void) => {
  const dispatch = useDispatch();
  const fetchUser = useCallback(async () => {
    fetch(`${getApiBaseUrl()}api/me`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.user) {
            dispatch(
              setUser({
                email: data.user.email,
                name: data.user.userName,
                role: data.user.role,
                permission: data.user.permission,
                id: data.user.id
              })
            );
          }
          data.csrf && dispatch(setToken(data.csrf));
        }
      });
  }, []);

  return fetchUser;
};

/**
 * This hook is initially called in the app to check whether the user is authenticated or not.
 */
export const useAuth = (): UserState => {
  const fetchUser = useFetchUser();
  const userState = useSelector<GlobalState, UserState>((state) => state.user);

  useEffect(() => {
    if (userState.user === null) {
      // fetch api/me to see if the user is still logged in and also to get an initial csrf token
      fetchUser();
    }
  }, [userState.user]);

  return userState;
};
