import { createRef, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ComboBox,
  Dropdown,
  Form,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toggle
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import {
  ActionBar,
  ActionBarCenter,
  ActionBarLeft,
  ActionBarRight
} from '../../../../building-blocks/action-bar/action-bar';
import { BreadcrumbNavigation } from '../../../../building-blocks/breadcrumb-navigation/breadcrumb-navigation';
import { BackNavigation } from '../../../../controls/back-navigation/back-navigation';
import { ViewMode, ViewModeSwitcher } from '../../../../building-blocks/view-mode-switcher/view-mode-switcher';
import { useUrlSearchParams } from 'use-url-search-params';
import {
  ContentView,
  ContentViewSection,
  RequiredFormItem
} from '../../../../building-blocks/content-view/content-view';
import styles from './search-request-edit.module.scss';
import { SaveBar } from '../../../../building-blocks/save-bar/save-bar';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSearchRequestsForm,
  SearchRequestObserver,
  setAssignee,
  setIsActive,
  setObservers,
  setPhone,
  setRole
} from '../search-request.state';
import { useNavigate, useParams } from 'react-router-dom';
import { searchRequestValidationSchema } from '../search-request.validation';
import { useFormValidation } from '../../../../../utils/validation/validation-utils';
import {
  useOpenCloseSearchRequests,
  usePermissionMappings,
  usePostNewSearchRequestsMutation,
  useUpdateSearchRequest
} from '../search-request.hooks';
import { FetchResult } from '@apollo/client';
import { SearchRequestViewProps } from '../search-request';
import { DeleteSVGIcon } from '@react-md/material-icons';
import { GlobalState } from '../../../../../redux/store';
import { FormBlockingDialog } from '../../../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import { setIsAnyFormDirty } from '../../../../../redux/forms.state';
import SearchRequestEditContact from './search-request-edit-contact/search-request-edit-contact';
import SearchRequestEditInfo from './search-request-edit-info/search-request-edit-info';
import classNames from 'classnames';
import { clearUpPhoneNumber } from '../../../../../utils/validation/input-validation';

export const SearchRequestEdit: FC<SearchRequestViewProps> = ({ formState, entityViewActions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useUrlSearchParams({}, {});
  const { uuid } = useParams();
  const [observerComboBoxValue, setObserverComboBoxValue] = useState<string | null>(null);
  const observerComboBoxRef = createRef<ComboBox<SearchRequestObserver>>();
  const userMail = useSelector<GlobalState, string | undefined>((state) => state.user.user?.email);
  const permissionMappingsData = usePermissionMappings();
  const isLoaded = uuid ? formState.uuid === uuid : true;

  const isCreationMode = useMemo(() => {
    return uuid === undefined || uuid === 'new';
  }, [uuid]);

  // obtain list of selectable employees
  const employees = useMemo(() => {
    if (permissionMappingsData && permissionMappingsData.permissionMappings && isLoaded) {
      const observerMails = formState.observers.map((observer) => observer.email);
      return (
        permissionMappingsData.permissionMappings
          // only employees that are not observers
          .filter((mappingEntry) => observerMails.indexOf(mappingEntry.email) === -1)
          // only employees that have the selected role
          .filter((mappingEntry) => mappingEntry.role === formState.role)
          .map((mappingEntry) => ({
            mail: mappingEntry.email
          }))
      );
    }
    return [];
  }, [permissionMappingsData, formState.role, formState.observers, isLoaded]);

  // obtain list of selectable observers
  const observers: SearchRequestObserver[] = useMemo(() => {
    if (permissionMappingsData && permissionMappingsData.permissionMappings && isLoaded) {
      const observerMails = formState.observers.map((observer) => observer.email);
      return (
        permissionMappingsData.permissionMappings
          // only employees that are not already observers
          .filter((mappingEntry) => observerMails.indexOf(mappingEntry.email) === -1)
          // only employees that are not assigned to the search request
          .filter((mappingEntry) => mappingEntry.email !== formState.assignee)
          // filter by string matching
          .filter((mappingEntry) =>
            observerComboBoxValue ? mappingEntry.email.startsWith(observerComboBoxValue) : true
          )
          .map((mappingEntry) => ({
            email: mappingEntry.email,
            role: mappingEntry.role
          }))
      );
    }
    return [];
  }, [permissionMappingsData, formState.assignee, formState.observers, observerComboBoxValue, isLoaded]);

  // obtain list of selectable roles
  const availableRoleItems = useMemo(() => {
    if (permissionMappingsData && permissionMappingsData.permissionMappings !== null && isLoaded) {
      const observerMails = formState.observers.map((observer) => observer.email);
      return Array.from(
        // use a set to get unique roles
        new Set<string>(
          permissionMappingsData.permissionMappings
            // only roles of employees that are not already observers
            .filter((mappingEntry) => observerMails.indexOf(mappingEntry.email) === -1)
            .map((mapping) => mapping.role)
        )
      ).map((role) => ({
        id: role,
        text: t(`enums.role.${role}`)
      }));
    }
    return [];
  }, [permissionMappingsData, formState.observers, isLoaded]);

  useEffect(() => {
    if (
      isCreationMode &&
      userMail &&
      permissionMappingsData &&
      permissionMappingsData.permissionMappings !== null &&
      formState.assignee === null &&
      formState.role === ''
    ) {
      // prefill role and assignee to current user
      const currentUserRoleMapping = permissionMappingsData.permissionMappings.find(
        (mapping) => mapping.email === userMail
      );
      if (currentUserRoleMapping) {
        dispatch(setAssignee(userMail));
        dispatch(setRole(currentUserRoleMapping.role));
        dispatch(setIsAnyFormDirty(false));
      }
    }
  }, [isCreationMode, permissionMappingsData, formState.assignee, userMail, formState.role]);

  const postNewSearchRequest = usePostNewSearchRequestsMutation();
  const updateSearchRequest = useUpdateSearchRequest();
  const { openSearchRequest, closeSearchRequest } = useOpenCloseSearchRequests();
  const { clearFormError, getValidationPropsForField, validateState } = useFormValidation(
    searchRequestValidationSchema,
    formState
  );

  const saveFunction = useCallback(() => {
    if (formState.phone) {
      // user is allowed to input anything, we afterwords clean up disallowed characters
      dispatch(setPhone(clearUpPhoneNumber(formState.phone)));
    }
    validateState(() => {
      if (formState.id) {
        updateSearchRequest().then(() => {
          dispatch(setIsAnyFormDirty(false));
        });
      } else {
        postNewSearchRequest().then((result: FetchResult): void => {
          if (result.data && result.data.addSearchRequest) {
            const newUuid = result.data.addSearchRequest;
            dispatch(resetSearchRequestsForm());
            navigate(`/search-requests/${newUuid}/view`);
          }
        });
      }
    });
  }, [dispatch, resetSearchRequestsForm, validateState]);

  // If we are in creation mode and an id is still set, we need to reset the form.
  // This can happen by updating an existing listing, then using the back navigation
  // and then creating a new one.
  useEffect(() => {
    if (isCreationMode && formState?.id !== null) {
      dispatch(resetSearchRequestsForm());
    }
  }, [isCreationMode, formState?.id]);

  return (
    <div>
      <BreadcrumbNavigation
        resolver={[
          null,
          (segment) => {
            return segment === 'new' ? t('views.searchRequests.newSearchRequest') : `ID ${uuid}`;
          },
          () => null // we do not want view mode in breadcrumbs
        ]}
      />
      <BackNavigation
        headline={
          isCreationMode
            ? `${t('views.searchRequests.newSearchRequest')}`
            : `${t('views.searchRequests.searchRequest')}`
        }
        subHeadline={isCreationMode ? undefined : formState.firstName + ' ' + formState.lastName}
        navigateTo="/search-requests"
      />

      <ActionBar>
        <ActionBarLeft>
          <Toggle
            id="toggleActivation"
            labelA={t('views.searchRequests.inactive')}
            labelB={t('views.searchRequests.active')}
            toggled={formState?.isActive}
            disabled={isCreationMode}
            onToggle={(isChecked) => {
              (isChecked ? openSearchRequest() : closeSearchRequest()).then(() => dispatch(setIsActive(isChecked)));
            }}
            checked={formState.isActive}
          />
        </ActionBarLeft>
        <ActionBarCenter>{!isCreationMode && entityViewActions}</ActionBarCenter>
        <ActionBarRight>{!isCreationMode && <ViewModeSwitcher viewMode={ViewMode.EDIT} />}</ActionBarRight>
      </ActionBar>

      <Form
        id="search-requests-data"
        noValidate
        onSubmit={(event) => {
          // prevent submit since we have custom validation logic and requests
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ContentView className={classNames(styles.Content, 'global-content-wrapper-block')}>
          <ContentViewSection
            className={styles.ContentViewSection}
            title={t('views.searchRequests.sections.contact')}
            anchorId="contact"
          >
            <SearchRequestEditContact
              formState={formState}
              getValidationPropsForField={getValidationPropsForField}
              clearFormError={clearFormError}
            />
          </ContentViewSection>
          <ContentViewSection
            className={styles.ContentViewSection}
            title={t('views.searchRequests.sections.searchInformation')}
            anchorId="searchInformation"
          >
            <SearchRequestEditInfo
              formState={formState}
              getValidationPropsForField={getValidationPropsForField}
              clearFormError={clearFormError}
            />
          </ContentViewSection>
          <ContentViewSection title={t('views.searchRequests.sections.responsibility')} anchorId="responsibility">
            <div className={styles.ResponsibilityFormRow}>
              <RequiredFormItem isEditMode>
                <Dropdown
                  id="role"
                  titleText={t('formFields.role') as string}
                  label={t('formFields.pleaseSelect') as string}
                  disabled={availableRoleItems.length === 0}
                  items={availableRoleItems}
                  itemToString={(item) => (item ? item.text : '')}
                  selectedItem={availableRoleItems.find((role) => formState.role === role.id)}
                  {...getValidationPropsForField('role', ({ selectedItem }) => {
                    selectedItem && dispatch(setRole(selectedItem.id));
                    dispatch(setAssignee(null));
                  })}
                />
              </RequiredFormItem>
              <RequiredFormItem isEditMode>
                <Dropdown
                  id="assignee"
                  titleText={t('formFields.employee') as string}
                  label={t('formFields.pleaseSelect') as string}
                  disabled={employees.length === 0 || formState.role.length === 0}
                  items={employees}
                  itemToString={(item) => (item ? item.mail : '')}
                  selectedItem={employees.find((employee) => employee.mail === formState.assignee) || null}
                  {...getValidationPropsForField(
                    'assignee',
                    ({ selectedItem }) => selectedItem && dispatch(setAssignee(selectedItem.mail))
                  )}
                />
              </RequiredFormItem>
            </div>
            <div className={styles.ResponsibilityFormRow}>
              <ComboBox
                id="observer"
                titleText={t('formFields.observers') as string}
                placeholder={t('formFields.selectEmailAddress') as string}
                ref={observerComboBoxRef}
                items={observers}
                itemToString={(item) => (item ? item.email : '')}
                selectedItem={null}
                onChange={({ selectedItem }) => {
                  if (selectedItem) {
                    dispatch(setObservers([...formState.observers, selectedItem]));
                    setObserverComboBoxValue(null);

                    observerComboBoxRef.current &&
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      (observerComboBoxRef.current.textInput.current as HTMLInputElement).blur();
                  }
                }}
                onInputChange={(inputValue) => setObserverComboBoxValue(inputValue || null)}
              />
            </div>
            <Table className={styles.ResponsibilityTable}>
              <TableBody>
                {formState.observers.map((observer, index) => (
                  <TableRow key={index}>
                    <TableCell>{observer.email}</TableCell>
                    <TableCell>{t(`enums.role.${observer.role}`)}</TableCell>
                    <TableCell>
                      <Button
                        type="button"
                        kind="ghost"
                        size="field"
                        renderIcon={DeleteSVGIcon}
                        iconDescription={t('tooltips.delete')}
                        hasIconOnly
                        onClick={() => {
                          const arrayWithoutElement = formState.observers.slice();
                          arrayWithoutElement.splice(index, 1);
                          dispatch(setObservers([...arrayWithoutElement]));
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ContentViewSection>
        </ContentView>
      </Form>

      <SaveBar trigger={params}>
        <div>
          <Button
            kind={'secondary'}
            size="default"
            onClick={() => {
              navigate('/search-requests');
            }}
          >
            {t('actions.cancel')}
          </Button>
        </div>
        <Button onClick={saveFunction} type="submit" form="search-requests-data">
          {t('actions.save')}
        </Button>
      </SaveBar>

      <FormBlockingDialog onResetForm={() => dispatch(resetSearchRequestsForm())} currentlyEditedItemUUID={uuid} />
    </div>
  );
};

export default SearchRequestEdit;
