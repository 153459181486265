import { FC, useEffect, useState } from 'react';
import { Button, TextArea, TextInput, Toggle } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import {
  ActionBar,
  ActionBarCenter,
  ActionBarLeft,
  ActionBarRight
} from '../../../../building-blocks/action-bar/action-bar';
import { BreadcrumbNavigation } from '../../../../building-blocks/breadcrumb-navigation/breadcrumb-navigation';
import { BackNavigation } from '../../../../controls/back-navigation/back-navigation';
import { ViewMode, ViewModeSwitcher } from '../../../../building-blocks/view-mode-switcher/view-mode-switcher';
import { useUrlSearchParams } from 'use-url-search-params';
import { ContentView, ContentViewSection } from '../../../../building-blocks/content-view/content-view';
import styles from './search-request-view.module.scss';
import { SaveBar } from '../../../../building-blocks/save-bar/save-bar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { buildSearchRequestFilter, formatRange } from '../search-request.utils';
import { Card } from '../../../../controls/card/card';
import { READ_ONLY_PROPS } from '../../../../../utils/constants';
import { formatDate } from '../../../../../utils/intl/dates-and-times';
import { SearchRequestViewProps } from '../search-request';
import { resetSearchRequestsForm, setIsActive, setShowResultToast } from '../search-request.state';
import { useOpenCloseSearchRequests } from '../search-request.hooks';
import { CommentSection } from '../../../../building-blocks/comment-section/comment-section';
import { useCommentRequests, useSearchRequestComments, useSendMail } from './search-request-view.hooks';
import CommentBox from '../../../../controls/comment-box/comment-box';
import { GlobalState } from '../../../../../redux/store';
import { UserState } from '../../../../../redux/user.state';
import { ConfirmationDialog } from '../../../../building-blocks/confirmation-dialog/confirmation-dialog';
import { FormBlockingDialog } from '../../../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import { SendMailDialog } from '../../../../building-blocks/send-mail-dialog/send-mail-dialog';
import { MailSection } from '../../../../building-blocks/mail-section/mail-section';
import { useLazyQuery } from '@apollo/client';
import { GET_EMAILS } from '../../../../../graphql/queries/mail';
import { GetEmails, GetEmailsVariables, GetEmails_receiveEmails } from '../../../../../graphql/types/GetEmails';
import classNames from 'classnames';

export const SearchRequestView: FC<SearchRequestViewProps> = ({ formState, entityViewActions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useUrlSearchParams({}, {});
  const { uuid } = useParams();
  const { openSearchRequest, closeSearchRequest } = useOpenCloseSearchRequests();
  const { addComment, deleteComment, updateComment } = useCommentRequests();
  const { refetchComments, data: commentData } = useSearchRequestComments();
  const [commentToDeleteId, setCommentToDeleteId] = useState<string | null>(null);
  const [isSendMailDialogOpen, setIsSendMailDialogOpen] = useState(false);
  const [initialResponseSubject, setInitialResponseSubject] = useState('');
  const sendMail = useSendMail();

  const userState = useSelector<GlobalState, UserState>((state) => state.user);
  const [fetchEmails, getEmailsQueryData] = useLazyQuery<GetEmails, GetEmailsVariables>(GET_EMAILS, {
    variables: { email: formState.email },
    fetchPolicy: 'no-cache'
  });
  const isLoaded = uuid ? formState.uuid === uuid : true;

  useEffect(() => {
    if (formState.email && formState.email.length > 0 && isLoaded) {
      fetchEmails();
    }
  }, [formState.email, isLoaded]);

  const [unreadMails, setUnreadMails] = useState<GetEmails_receiveEmails[]>([]);

  useEffect(() => {
    if (getEmailsQueryData.data && getEmailsQueryData.data.receiveEmails) {
      setUnreadMails(
        getEmailsQueryData.data.receiveEmails.filter((mail) => mail.unseen && mail.to !== formState.email)
      );
      const lastSubject = getEmailsQueryData.data.receiveEmails
        .slice()
        .reverse()
        .find((mail) => mail.from.indexOf(formState.email) > -1)?.subject;

      setInitialResponseSubject(lastSubject ? `Re: ${lastSubject}` : '');
    }
  }, [getEmailsQueryData.data]);

  const fullName = (): string => {
    return formState.firstName + ' ' + formState.lastName;
  };

  const fullNameLabel = (): string => {
    return t('formFields.firstName') + ' ' + t('formFields.lastName');
  };

  const formatDistricts = (): string => {
    let districts = '';
    if (formState.districts) {
      formState.districts.forEach((district, index) => {
        districts += (index > 0 ? ', ' : '') + t(`enums.district.${district}`);
      });
    }
    return districts.length === 0 ? '\u2013' : districts;
  };

  return (
    <div>
      <BreadcrumbNavigation
        resolver={[
          null,
          () => {
            return `ID ${uuid}`;
          },
          () => null // we do not want view mode in breadcrumbs
        ]}
      />
      <BackNavigation
        headline={`${t('views.searchRequests.searchRequest')}`}
        subHeadline={fullName()}
        navigateTo="/search-requests"
      />

      <ActionBar>
        <ActionBarLeft>
          <Toggle
            id="toggleActivation"
            labelA={t('views.searchRequests.inactive')}
            labelB={t('views.searchRequests.active')}
            toggled={formState?.isActive}
            onToggle={(isChecked) => {
              (isChecked ? openSearchRequest() : closeSearchRequest()).then(() => dispatch(setIsActive(isChecked)));
            }}
            checked={formState.isActive}
          />
        </ActionBarLeft>
        <ActionBarCenter>{entityViewActions}</ActionBarCenter>
        <ActionBarRight>
          <ViewModeSwitcher viewMode={ViewMode.VIEW} />
        </ActionBarRight>
      </ActionBar>

      <ContentView className={classNames(styles.Content, 'global-content-wrapper-block')}>
        <ContentViewSection title={t('views.searchRequests.sections.internalComments')} anchorId="comments">
          <p className={styles.SectionInfo}>{t('views.searchRequests.internalCommentsInfoText')}</p>
          <CommentSection
            onSubmitNewComment={(content) =>
              addComment({
                variables: {
                  content,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  searchRequest: formState.id!
                }
              }).then(() => refetchComments())
            }
          >
            {commentData &&
              commentData.searchRequestCommentsForSearchRequest.map((comment, index) => (
                <CommentBox
                  key={index}
                  comment={comment.content}
                  userName={comment.authorName}
                  canBeEditedOrDeletedByUser={userState.user?.email === comment.authorEmail}
                  date={comment.modifiedAt}
                  onDelete={() => setCommentToDeleteId(comment.id)}
                  onEdit={(newContent) =>
                    updateComment({
                      variables: {
                        searchRequestComment: {
                          content: newContent,
                          id: comment.id
                        }
                      }
                    }).then(() => refetchComments())
                  }
                />
              ))}
          </CommentSection>
          <ConfirmationDialog
            title={t('views.searchRequests.comments.modalDelete.title')}
            headline={t('views.searchRequests.comments.modalDelete.headline')}
            text={t('views.searchRequests.comments.modalDelete.text')}
            primaryActionLabel={t('actions.delete')}
            open={commentToDeleteId !== null}
            onPrimaryActionClick={() => {
              commentToDeleteId &&
                deleteComment({ variables: { id: commentToDeleteId } }).then(() => refetchComments());
              setCommentToDeleteId(null);
            }}
            onCloseClick={() => setCommentToDeleteId(null)}
          />
        </ContentViewSection>
        <ContentViewSection
          title={t('views.searchRequests.sections.mailInbox')}
          anchorId="inbox"
          tagCount={unreadMails.length}
        >
          <div>
            <p className={styles.SectionInfo}>{t('views.searchRequests.mailInboxInfoText')}</p>
            <Button
              disabled={!userState.user?.email}
              onClick={() => {
                setIsSendMailDialogOpen(true);
              }}
            >
              {t('actions.writeMail')}
            </Button>
          </div>
          <MailSection
            email={formState.email}
            getEmailsQueryData={getEmailsQueryData}
            onMailSeen={(id) => setUnreadMails(unreadMails.filter((mail) => mail.id !== id))}
          />
        </ContentViewSection>
        <ContentViewSection title={t('views.searchRequests.sections.contact')} anchorId="contact">
          <Card className={styles.Contact}>
            <div className={styles.ContactData}>
              <h3>{t('views.searchRequests.contactData')}</h3>
              <p aria-label={t('formFields.salutation')}>
                {formState.gender ? t(`enums.salutation.${formState.gender}`) : ''}
              </p>
              <p aria-label={fullNameLabel()}>{fullName()}</p>
              <p aria-label={t('formFields.affiliationType')}>
                {formState.affiliationType && formState.gender
                  ? t(`enums.affiliationTypeGender.${formState.gender}.${formState.affiliationType}`)
                  : ''}
              </p>
              {formState.department ? (
                <p aria-label={t('formFields.department')}>{t(`enums.department.${formState.department}`)}</p>
              ) : undefined}
              <p className={styles.contactEmail} aria-label={t('formFields.emailAddress')}>
                {formState.email}
              </p>
              {formState.phone ? <p aria-label={t('formFields.telephoneNumber')}>{formState.phone}</p> : undefined}
            </div>
            <div>
              <TextInput
                id="country"
                labelText={t('formFields.country') as string}
                value={formState?.country ? (t(`enums.country.${formState.country}`) as string) : '\u2013'}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="prefLanguage"
                labelText={t('formFields.prefLanguage') as string}
                value={
                  formState
                    ? (t(`formFields.language.${formState.prefersEnglish ? 'english' : 'german'}`) as string)
                    : ''
                }
                {...READ_ONLY_PROPS}
              />
            </div>
          </Card>
        </ContentViewSection>
        <ContentViewSection
          className={styles.SearchInformation}
          title={t('views.searchRequests.sections.searchInformation')}
          anchorId="searchInformation"
        >
          <Card>
            <h3 id="object">{t('views.searchRequests.sections.subsections.object')}</h3>
            <div className={styles.Group} aria-labelledby="object">
              <TextInput
                id="listingType"
                labelText={t('views.listings.listingType') as string}
                value={formState ? (t(`enums.listingType.${formState.type}`) as string) : '\u2013'}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="numberOfRooms"
                labelText={t('formFields.numberOfRooms') as string}
                value={formState && formState.numberOfRooms !== null ? formState.numberOfRooms : '\u2013'}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="livingSpace"
                labelText={t('formFields.livingSpace') as string}
                value={formState && formatRange(t, formState.squareMeterFrom, formState.squareMeterTo, 'm²')}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="numberOfTenants"
                labelText={t('formFields.numberOfTenants') as string}
                value={(formState && formState.numberOfTenants) || '\u2013'}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="moveInDate"
                labelText={t('formFields.moveInDate') as string}
                value={(formState && formState.availableFrom && formatDate(formState.availableFrom)) || '\u2013'}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="moveOutDate"
                labelText={t('formFields.moveOutDate') as string}
                value={
                  formState && formState.availableUntil
                    ? formatDate(formState.availableUntil)
                    : (t('formFields.openEnded') as string)
                }
                {...READ_ONLY_PROPS}
              />
            </div>
            <h3 id="location">{t('views.searchRequests.sections.subsections.location')}</h3>
            <div className={styles.Group} aria-labelledby="location">
              <TextInput
                id="city"
                labelText={t('formFields.city') as string}
                value={formState && formState.city ? (t(`enums.city.${formState.city}`) as string) : '\u2013'}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                className={styles.textOverflow}
                id="district"
                labelText={t('formFields.district') as string}
                value={formatDistricts()}
                title={formatDistricts()}
                {...READ_ONLY_PROPS}
              />
            </div>
            <h3 id="cost">{t('views.searchRequests.sections.subsections.cost')}</h3>
            <div className={styles.Group} aria-labelledby="cost">
              <TextInput
                id="totalRent"
                labelText={t('formFields.totalRent') as string}
                value={formState && formatRange(t, formState.totalRentFrom, formState.totalRentTo, '€')}
                {...READ_ONLY_PROPS}
              />
              <TextInput
                id="deposit"
                labelText={t('formFields.deposit') as string}
                value={(formState && formState.depositMax) || '\u2013'}
                {...READ_ONLY_PROPS}
              />
            </div>
            {formState?.tags.length > 0 && (
              <>
                <h3>{t('views.searchRequests.sections.subsections.configuration')}</h3>
                <ul>
                  {formState.tags.map((tag) => {
                    return <li key={tag}>{t(`enums.tags.${tag}`)}</li>;
                  })}
                </ul>
              </>
            )}
            {formState?.remarks && (
              <>
                <h3>{t('views.searchRequests.sections.subsections.remarks')}</h3>
                <TextArea
                  id="remarks"
                  labelText={t('formFields.remarks') as string}
                  value={(formState && formState.remarks) || '\u2013'}
                  {...READ_ONLY_PROPS}
                />
              </>
            )}
          </Card>
        </ContentViewSection>
        <ContentViewSection title={t('views.searchRequests.sections.responsibility')} anchorId="responsibility">
          <Card className={styles.Responsibility}>
            <TextInput
              id="role"
              labelText={t('formFields.role') as string}
              value={formState.role ? (t(`enums.role.${formState.role}`) as string) : '\u2013'}
              {...READ_ONLY_PROPS}
            />
            <TextInput
              id="employee"
              labelText={t('formFields.employee') as string}
              value={formState.assignee || '\u2013'}
              {...READ_ONLY_PROPS}
            />
            <TextInput
              id="observers"
              labelText={t('formFields.observers') as string}
              value={
                formState.observers.length > 0
                  ? formState.observers.map((observer) => observer.email).join(', ')
                  : '\u2013'
              }
              {...READ_ONLY_PROPS}
            />
          </Card>
        </ContentViewSection>
      </ContentView>

      <SaveBar trigger={params}>
        <div />
        <Button
          onClick={() => {
            navigate('/listings' + buildSearchRequestFilter(formState));
            dispatch(setShowResultToast(true));
          }}
          type="button"
        >
          {t('actions.findListings')}
        </Button>
      </SaveBar>

      <FormBlockingDialog onResetForm={() => dispatch(resetSearchRequestsForm())} currentlyEditedItemUUID={uuid} />
      {isSendMailDialogOpen && (
        <SendMailDialog
          open={isSendMailDialogOpen}
          receiver={formState.email}
          initialSubject={initialResponseSubject}
          onClose={() => {
            setIsSendMailDialogOpen(false);
          }}
          onSendMail={(mailInfo) => {
            userState.user &&
              userState.user.email &&
              sendMail({
                variables: {
                  email: {
                    from: userState.user.email,
                    cc: mailInfo.mailCCAddresses.join(','),
                    to: formState.email,
                    subject: mailInfo.mailSubject,
                    html: mailInfo.mailContent,
                    attachments: mailInfo.mailAttachments
                  }
                }
              }).then(() => {
                // Mail is successfully send, but might not be in the inbox yet
                setTimeout(() => {
                  fetchEmails({ variables: { email: formState.email } });
                }, 100);
              });
            setIsSendMailDialogOpen(false);
          }}
        ></SendMailDialog>
      )}
    </div>
  );
};

export default SearchRequestView;
