import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { logger } from './utils/logger';

i18n
  // Load translation using http -> see /public/locales.
  .use(I18NextHttpBackend)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false // Not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}-2407310919.json'
    }
  })
  .catch((e) => logger.error(e));

i18n.on('languageChanged', (language) => {
  document.documentElement.lang = language;
});

export default i18n;
