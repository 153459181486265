import { FC, useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { ToastNotification } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../redux/store';
import { getApiBaseUrl } from '../utils/api';
import { IS_DEV_MODE, START_VIEW_PATH } from '../utils/constants';
import { TOAST_DEFAULT_PROPERTIES } from '../utils/hooks/query-result-toast';
import { logger } from '../utils/logger';

const TUMApolloProvider: FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorHandleLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (extensions?.code === 'UNAUTHENTICATED') {
          dispatch(logout());
          navigate(START_VIEW_PATH);
          toast(
            () => (
              <ToastNotification
                kind="error"
                title={t('notifications.sessionExpiredTitle') as string}
                subtitle={t('notifications.sessionExpiredSubTitle') as string}
                caption=""
              />
            ),
            TOAST_DEFAULT_PROPERTIES
          );
        }
      });

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`);
    }
  });

  const apolloServerUri = `${getApiBaseUrl()}graphql` || '/graphql';

  // Create apollo client
  const apolloClient = useMemo(() => {
    return new ApolloClient({
      uri: apolloServerUri,
      cache: new InMemoryCache({
        resultCaching: false
      }),
      link: ApolloLink.from([
        errorHandleLink,
        createUploadLink({
          uri: apolloServerUri
        })
      ]),
      connectToDevTools: IS_DEV_MODE
    });
  }, []);

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default TUMApolloProvider;
