import {
  AddSVGIcon,
  BusinessSVGIcon,
  EditSVGIcon,
  PersonSVGIcon,
  VisibilityOffSVGIcon,
  VisibilitySVGIcon
} from '@react-md/material-icons';
import {
  Button,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag
} from 'carbon-components-react';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContactType } from '../../../graphql/types/globalTypes';
import { ContentView, ContentViewSection } from '../../building-blocks/content-view/content-view';
import { SaveBar } from '../../building-blocks/save-bar/save-bar';
import { Card } from '../../controls/card/card';
import { useLandlordUserData } from './account-view.hooks';
import styles from './account-view.module.scss';
import { ListingCardContainer } from '../../building-blocks/listing-card-container/listing-card-container';
import { useResponsiveBreakpoints } from '../../../utils/hooks/responsive.hooks';
import { useDocumentTitle } from '../../../utils/hooks/document-title';
import EditContactDetailsDialog from '../edit-contact-details-dialog/edit-contact-details-dialog';
import { useDispatch } from 'react-redux';
import { setLandlordProfileData } from './account-view.state';

export const VISIBILITY_ATTRIBUTE_KEYS = ['companyName', 'firstName', 'lastName', 'phone', 'email'];

interface NeedsTranslationProps {
  t: (key: string) => string;
}

const VisibilityIcon: FC<{ visible?: boolean } & NeedsTranslationProps> = ({ visible, t }) => {
  return !visible ? (
    <VisibilityOffSVGIcon className={styles.DisabledIcon} title={t('icons.visible')} />
  ) : (
    <VisibilitySVGIcon title={t('icons.notVisible')} />
  );
};

const ContactDataInfoText: FC<NeedsTranslationProps> = ({ t }) => {
  return (
    <span id="contactDataInfo" className={classNames(styles.InfoText, styles.InfoTextSmall)}>
      {t('views.account.contactDataVisibilityInfoText')}
    </span>
  );
};

const ContactPartnerInfoText: FC<NeedsTranslationProps> = ({ t }) => {
  return (
    <span id="contactPartnerInfo" className={classNames(styles.InfoText, styles.InfoTextSmall)}>
      {t('views.account.contactPartnerVisibilityInfoText')}
    </span>
  );
};

const ContactDataHeading: FC<NeedsTranslationProps & { contactType?: ContactType }> = ({ t, contactType }) => (
  <div className={styles.ContactDataHeading}>
    {contactType === ContactType.PRIVATE ? <PersonSVGIcon /> : <BusinessSVGIcon />}{' '}
    {contactType && t(`enums.contactType.${contactType}`)}
  </div>
);

// invisible table header for improved accessibility
const ContactDataTableHeader: FC<NeedsTranslationProps> = ({ t }) => (
  <TableHead className={styles.ContactDataTableHeader}>
    <TableRow>
      <TableHeader>{t('main.visibility')}</TableHeader>
      <TableHeader>{t('main.attribute')}</TableHeader>
      <TableHeader>{t('main.value')}</TableHeader>
    </TableRow>
  </TableHead>
);

export const AccountView: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeListings, setActiveListings] = useState(0);
  const [inactiveListings, setInactiveListings] = useState(0);
  const [openEditContactDetails, setOpenEditContactDetails] = useState(false);

  const breakpoints = useResponsiveBreakpoints();
  useDocumentTitle(t('navigation.mySpace'));

  const {
    userState,
    landlordProfileData,
    getLandLordQuery: { loading, data: userData, refetch }
  } = useLandlordUserData();

  // Fetch listings for landlord
  const listings = userData?.landlord?.listings;

  useEffect(() => {
    if (userData?.landlord?.listings) {
      const countActive = listings?.filter((listing) => listing?.isActive).length || 0;
      setActiveListings(countActive);
      setInactiveListings((listings?.length || 0) - countActive);
    }
  }, [userData?.landlord?.listings]);

  const visibilityRows = useMemo(() => {
    /* eslint-disable @typescript-eslint/ban-ts-comment  */
    return VISIBILITY_ATTRIBUTE_KEYS.map((key) => {
      const isInstitutionRow =
        key === 'companyName' && userData?.landlord?.contactType === ContactType.PUBLIC_INSTITUTION;
      return {
        key,
        label: isInstitutionRow ? t('views.common.tableHeaders.institution') : t(`views.common.tableHeaders.${key}`),
        // @ts-ignore
        value: userData?.landlord[key],
        // @ts-ignore
        visibility: landlordProfileData[`show${key.charAt(0).toUpperCase() + key.slice(1)}`]
      };
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment  */
  }, [userData?.landlord, landlordProfileData, t]);

  const resetProfileData = useCallback(() => {
    userData?.landlord &&
      dispatch(
        setLandlordProfileData({
          showFirstName: userData?.landlord.showFirstName,
          showLastName: userData?.landlord.showLastName,
          showEmail: userData?.landlord.showEmail,
          showPhone: userData?.landlord.showPhone,
          showCompanyName: userData?.landlord.showCompany
        })
      );
  }, [userData?.landlord]);

  useEffect(() => {
    if (userData?.landlord) {
      resetProfileData();
    }
  }, [resetProfileData]);

  const createNewButton = (
    <Button
      onClick={() => {
        navigate('./new');
      }}
      className={styles.CreateNewButton}
      renderIcon={AddSVGIcon}
    >
      {t('actions.createNewListing')}
    </Button>
  );

  return (
    <>
      <div className={styles.Greeting}>
        <div className="global-content-wrapper">
          <h1>
            {t('main.greeting', {
              title: '',
              name: userState.user?.name || ''
            })}
          </h1>

          {!breakpoints.isMobile && (
            <Button
              kind={'secondary'}
              size="field"
              onClick={() => {
                navigate('./new');
              }}
              className={styles.CreateNewButton}
              renderIcon={AddSVGIcon}
            >
              {t('actions.createNewListing')}
            </Button>
          )}
        </div>
      </div>
      <ContentView className={classNames(styles.AccountContent, 'global-content-wrapper-block')}>
        <ContentViewSection
          title={t('views.account.myListings')}
          anchorId="listings"
          suffix={
            <>
              {activeListings > 0 ? (
                <Tag className={styles.ActiveTag}>{t('views.account.active', { count: activeListings })}</Tag>
              ) : undefined}
              {inactiveListings > 0 ? (
                <Tag className={styles.InactiveTag}>{t('views.account.inactive', { count: inactiveListings })}</Tag>
              ) : undefined}
            </>
          }
        >
          <ListingCardContainer
            listings={listings}
            emptyText={t('views.account.createListingInfoText') as string}
            refetch={refetch}
            editable={true}
          />
          {breakpoints.isMobile ? (
            <SaveBar trigger={null} className={styles.MobileCreateNewBar}>
              {createNewButton}
            </SaveBar>
          ) : (
            createNewButton
          )}
        </ContentViewSection>
        <ContentViewSection title={t('views.account.contactData')} anchorId="contact">
          {
            <Card className={styles.ContactDataCard}>
              <div className={styles.ContactDataCardInfoRow}>
                <ContactDataInfoText t={t} />
                <Button
                  kind="ghost"
                  size="field"
                  renderIcon={EditSVGIcon}
                  className={styles.EditContactDataVisibilityButton}
                  onClick={() => setOpenEditContactDetails(true)}
                >
                  {t('actions.editAlt')}
                </Button>
              </div>
              <ContactDataHeading t={t} contactType={userData?.landlord?.contactType} />

              {userData?.landlord ? (
                <Table aria-describedby="contactPartnerInfo" className={styles.ContactDataTable}>
                  <ContactDataTableHeader t={t} />
                  <TableBody>
                    {visibilityRows.map((rowData) => {
                      if (!(rowData.key === 'companyName' && userData?.landlord?.contactType === ContactType.PRIVATE)) {
                        return (
                          <Fragment key={rowData.key}>
                            <TableRow>
                              <TableCell>
                                <VisibilityIcon visible={rowData.visibility} t={t} />
                              </TableCell>
                              <TableCell>{rowData.label}</TableCell>
                              <TableCell>{rowData.value}</TableCell>
                            </TableRow>
                            {rowData.key === 'companyName' ? (
                              <TableRow>
                                <TableCell className={styles.ContactSubheading}>
                                  <ContactPartnerInfoText t={t} />
                                </TableCell>
                              </TableRow>
                            ) : undefined}
                          </Fragment>
                        );
                      }
                      // landlords that do not have a company or institution, don't need the showCompany visibility
                      return undefined;
                    })}
                  </TableBody>
                </Table>
              ) : loading ? (
                <DataTableSkeleton
                  showHeader={false}
                  showToolbar={false}
                  columnCount={3}
                  rowCount={VISIBILITY_ATTRIBUTE_KEYS.length}
                  className={styles.ContactDataTable}
                  compact={true}
                />
              ) : null}
            </Card>
          }
        </ContentViewSection>
      </ContentView>
      {openEditContactDetails && (
        <EditContactDetailsDialog
          onCloseModal={() => {
            setOpenEditContactDetails(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

export default AccountView;
